.bulletin input[type='email']::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
  line-height: 0.9;
}
.bulletin input[type='text']::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}
.bulletin input[type='password']::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}
.bulletin input[type='tel']::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}
.bulletin input[type='email']:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-align: center;
}
.bulletin input[type='email']::-ms-input-placeholder {
  /* Microsoft Edge */
  text-align: center;
}

.form-group {
  margin-bottom: 0.5rem;
}

.bg-airplane {
  background: url('/images/Boeing-737-Max-9.jpeg');
  background-position: 50% -3%;
  background-size: contain;
  background-repeat: no-repeat;
}
