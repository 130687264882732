table {
	table-layout: fixed;
	word-wrap: break-word;
}

.table-header-bg-gray {
	background: $gray;
}

.w-30 {
	width: 30%;
}

.w-20 {
	width: 25%;
}

.w-25 {
	width: 25%;
}

.w-33 {
	width: 33%;
}

.w-5 {
	width: 5%;
}

.w-10 {
	width: 10%;
}
.w-15 {
	width: 15%;
}
.w-20 {
	width: 20%;
}

.w-40 {
	width: 40%;
}

.w-50 {
	width: 50%;
}
.w-60 {
	width: 60%;
}

.w-80 {
	width: 80%;
}

.w-10px {
	width: 10px !important;
}
.w-20px {
	width: 20px !important;
}
.w-25px {
	width: 25px !important;
}
.w-35px {
	width: 35px !important;
}
.w-40px {
	width: 405px !important;
}
.w-50px {
	width: 50px !important;
}
.w-75px {
	width: 75px !important;
}
.w-80px {
	width: 80px !important;
}
.w-100px {
	width: 100px !important;
}
.w-120px {
	width: 120px;
}
.w-150px {
	width: 150px !important;
}
.w-200px {
	width: 200px !important;
}
.w-220px {
	width: 220px !important;
}
.w-250px {
	width: 250px !important;
}

.w-300px {
	width: 300px !important;
}

.w-310px {
	width: 310px !important;
}

.w-320px {
	width: 320px !important;
}

.w-325px {
	width: 325px !important;
}

.w-350px {
	width: 350px !important;
}

.w-375px {
	width: 375px !important;
}

.w-400px {
	width: 400px !important;
}

.table-head {
	background-color: $bg-light-blue;
}

.aircraft-brief .table th,
.table td {
	padding: 0.35rem;
}

.border-black {
	border: 1px solid black !important;
}
.border-gray {
	border: 1px solid #ced4da !important;
}
.border-light-blue {
	border-color: $bg-light-blue;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
	background-color: $bg-light-blue;
}
.table-striped > tbody > tr:nth-child(even) > td,
.table-striped > tbody > tr:nth-child(even) > th {
	background-color: #f8f9fa;
}

.table-striped-white > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
	background-color: $bg-light-blue;
}

.table th {
	font-weight: normal;
}
// .table-striped-white>tbody>tr:nth-child(even)>td,
// .table-striped>tbody>tr:nth-child(even)>th {
//     background-color: #fff;
// }

// .table-white, .table-white td{
//     background-color: #fff;
// }

.table-header-aux {
	background: #d8d8d8;
	color: #1f497d !important;
}

.table-header-aux th a {
	color: #1f497d !important;
}

@media (max-width: 600px) {
	.table-mobile {
		width: 800px;
		min-width: 800px;
	}
}
