.compare-table {
   width: 1200px;
   max-width: 1200px;
   border-spacing: 0;
   margin: auto;
}
.img-engine-thumbnail {
   width: 200px;
}
.engine-compare-column {
   width: 220px;  
   padding: 10px;
}
@media only screen and (max-width: 600px) {
   
.compare-table {
   width: 600px; 
   max-width: 600px;  
}
.img-aircraft-thumbnail {
   width: 100px;
}



}