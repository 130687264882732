


//  Gray

.bg-dark-gray {
    color: rgba(255, 255, 255, 1);
    background: rgb(44, 44, 44, 0.9);            
}

.bg-gray {
    color: rgba(255, 255, 255, 1);
    background: rgba(0, 0, 0, .5) !important;
}

.bg-gray-light {
    background: #D6DCE4;
    color: #000;
}
.bg-gray-light:hover {
    text-decoration: none;
}

.bg-gray-lighter {
    background: #EBF2FA;
}
 

.bg-success-mediun {
    background: #ABEDAC;
    color: rgba(0, 0, 0, 1);
}
.bg-success-light {
    background: #E1EFDA;
    color: rgba(0, 0, 0, 1);
}

/********************************
BLUE
*********************************/


.bg-dark-blue-gray {
    color: rgba(255, 255, 255, 1);
    background: $dark-blue-gray ;
}

.bg-some-blue {
    background: $some-blue;
    color: #fff;
}
 
.bg-light {
    color: #222;
    background: $light-blue;
}

.bg-light-blue {
    color: #222;
    background: $light-blue;
}
.bg-lighter-blue {
    color: #222 !important;
    background: $lighter-blue !important;
}
.bg-medium-blue {
    color: #fff;
    background: $medium-blue;
}

.bg-bright-blue {
    color: rgba(255, 255, 255, 1);
    background: $bright-blue;
}

.bg-dark-blue {
    background: $dark-blue;
    color: #fff;
}
 
.bg-darkest-blue {
    background: $darkest-blue;
    color: #fff;
}

.bg-dark-blue-welcome {
    background: $dark-blue-welcome;
    color: #fff;
}

.bg-blue {
    background: blue;
}

 
.text-light-blue {
    color: $light-blue;
}
.text-bright-blue {
    color: blue;
}
.text-medium-blue {
    color: $medium-blue;
}
.text-dark-blue {
    color: $dark-blue;
}
 
.bg-white-alpha-25 {
    background: rgba(255, 255, 255, 0.25);
}
 
.bg-white-alpha-50 {
    background: rgba(255, 255, 255, 0.50);
}
.bg-white-alpha-75 {
    background: rgba(255, 255, 255, 0.75);
}

.bg-white-alpha-85 {
    background: rgba(255, 255, 255, 0.85);
}

.text-gray-light {
    color: #aaa !important;
}

.text-dark {
    color: #333;
}

.text-darkest-blue {
    color: $darkest-blue;
}

.text-black {
    color: #000;
}
.text-about {
    color: #45546A;
}

.text-violet, 
.text-violet:hover{
    color: $violet !important;
}
.bg-violet, .btn-violet{
    background: $violet !important;
}

.subline-blue {
    color: #243961;
}




.bg-light-blue-tripped-1 {
    color: #fff;
    background: #DAE1F2;
}
.bg-light-blue-tripped-2 {
    color: #fff;
    background: #B3C6E7;
}


.btn-view-samples {
    color: #fff;
}
.btn-view-samples:hover {
    color: $violet;
}

.btn {
    font-size: 1em;
}
 

.bg-aux {
    background: $bg-aux;
}


.text-purple  {
    color: #7031A0
}

.btn-purple {
    background: #7031A0;
    background: #B672FF;
    background: #7031A0;
}

.bg-purple-light {
    background: #ede3f7; 
}






// welcome v2 
.bg-welcome-light-blue {
    background: $welcome-light-blue
}
.bg-welcome-dark-blue {
    background: $welcome-dark-blue
}