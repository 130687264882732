
// Buttons
.home-btn.btn {
text-transform: uppercase;
font-size: .75rem;
font-weight: $main-font-weight + 200;
color: lighten($main-font-color, 15%);
border-radius: 0;
padding: .75rem 1.25rem;
letter-spacing: 1px;
// min-width: 200px;
min-width: 150px;
}


.lead a {
font-size: 18px;
}

.home-btn.btn-gradient {
color: #fff;
background-color: $primary-color;
transition: color .3s ease-in-out;
transition: background .3s ease-in-out;

&:hover {
color: #333;
background-color: $primary-color-hover;
transition: color .3s ease-in-out;
}
}


.btn-circle.btn-sm {
width: 30px;
height: 30px;
padding: 6px 0px;
border-radius: 15px;
font-size: 8px;
text-align: center;
}
.btn-circle.btn-md {
width: 50px;
height: 50px;
padding: 7px 10px;
border-radius: 25px;
font-size: 10px;
text-align: center;
}
.btn-circle.btn-xl {
width: 70px;
height: 70px;
padding: 10px 16px;
border-radius: 35px;
font-size: 12px;
text-align: center;
}

.btn-help {
  color: #333;
  background-color: #dedede;
  border-color: #dedede;
  padding-top:2px;
  font-size: 14px;
}

.btn-light-yellow {
  background-color: #FDF2D0;
  color: #000;
  margin-right: 50px;
  min-width: 100px;
}

.btn-light-blue {
  background: #B6C6E4;
  margin-right: 5px;
}


.link-gold
, .text-gold
{
  color: #FBE4A2;
  font-weight: 100 !important;
}

a.link-gold:hover
, a.text-gold:hover
{
  color: #ffffff;
  font-weight: 100 !important;
}