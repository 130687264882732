@import 'variables';

.form-group label {
	margin-bottom: 0;
	margin-top: 0.75 rem;
}

.card,
.form-control {
	border-radius: 0;
	// border: 1px solid #222;
	font-size: 16px;
}

.btn-primary {
	background: #0062cc;
}

textarea.query {
	width: 250px;
	height: 150px;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
	opacity: 0;
}

// align placeholders to the center.
input {
	text-align: center;
	color: #000;
}

::-webkit-input-placeholder {
	text-align: center;
	font-size: 14px;
}

:-moz-placeholder {
	text-align: center;
	font-size: 14px;
}

::placeholder {
	color: #989898 !important;
	opacity: 0.6; /* Firefox */
	font-size: 14px;
}

::-ms-input-placeholder {
	/* Edge 12 -18 */
	color: #989898 !important;
	opacity: 0.6; /* Firefox */
}

// The code below helps add the eye to the passwords inputs.
.eye-checkbox {
	margin-top: 4px;
	color: #555;
	padding: 3px;
	font-size: 14px;
}
.eye-checkbox input[type='checkbox'],
.eye-checkbox .checked {
	display: none;
}

.eye-checkbox input[type='checkbox']:checked ~ .checked {
	display: inline-block;
}

.eye-checkbox input[type='checkbox']:checked ~ .unchecked {
	display: none;
}

input[type='radio'].xl-radio {
	transform: scale(1.66);
	margin-right: 10px;
}

.btn-clear-field {
	position: absolute;
	top: -4px;
	right: 13px;
	background: none !important;
}
