// Fonts
// @import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600"); //subscripton page

@import 'variables';
@import 'fonts';
@import 'subscription';
@import 'pricing-horizontal';
@import 'pricing';
// @import "sidebar";
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'updates';
// @import "sidebar-bootstrap-style2";
@import 'tables';
@import 'modal';
@import 'footer-bar';
@import 'buttons';
@import 'aircraft';
@import 'utilities';
@import 'launch-layout';
@import 'nav';
@import 'compare';
@import 'shows';
@import 'colors';
@import 'position';
@import 'guide';
@import 'searchglobal';
@import 'bulletin';
@import 'inputs';
@import 'cookie-consent';

/* used on the welcome scrolls */
@import 'text-padding-margin-border';
@import 'welcome-scroll';

@import '~vue-wysiwyg/dist/vueWysiwyg.css';
@import 'vue-select/dist/vue-select.css';

@import '~@fortawesome/fontawesome-free/css/all.css';
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

html,
body {
	color: $dark-blue-gray;
	// font-family: 'Open Sans', sans-serif;
	font-family: $font-family-sans-serif;
	font-weight: 300;
	font-size: 16px;
	line-height: 1.6rem !important; // normal 1.4 changed to 1.6 because of Open Sans.
	line-height: 100%;
	background: $gray-3;
}

body {
	padding-top: $body-mt;
	padding-bottom: $body-mb;
}

.wrapper {
	padding: 0 $body-mr $body-ml;
}

strong {
	font-weight: 900;
}

hr {
	border-top: 4px solid #305396;
}

ol li,
ul li {
	margin-bottom: 0.5rem !important;
}

p {
	margin-bottom: 1.2rem !important;
}

.navbar-nav .nav-link {
	padding-right: 0.4rem;
	padding-left: 0.4rem;
}

.nav-content {
	color: #ffffff;
	font-size: 1rem;
}

.navbar-brand,
.navbar,
.navbar-dark a.nav-link {
	color: #ffffff;
}

.navbar-light .navbar-brand {
	color: rgba(0, 0, 0, 0.9) !important;
}

.nav-admin-margin {
	margin-bottom: 0;
}

.nav-admin {
	background: #ffffff !important;
	margin-top: 60px;
	margin-bottom: 40px;
	z-index: 200;
	border-bottom: 1px solid #efefef;
}

.nav-admin ul li ul li a {
	color: #555555;
}

.nav-edit {
	background: #dddddd;
	z-index: 100000;
	padding: 10px 0 0;
}

.jumbotron {
	padding: 7.5rem 4em 4em 4em;
	margin-bottom: 0 !important;
	border-radius: 0;
	background: url('/images/Boeing-737-Max-9.jpeg') center center;
	background-repeat: no-repeat;
}

.homepage-slide {
	margin: -74px 0 0 0;
	color: #ffffff;
}

.welcome {
	background: url('/images/Boeing-737-Max-9.jpeg');
	background-position: bottom center;
	background-size: cover;
	background-repeat: no-repeat;
}

.footer-bg {
	background: url('/images/welcome-scroll/ATR72-600.png');
	background-position: bottom center;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-thumbs {
	padding: 5px;
	margin-bottom: 5px;
}

.img-helper {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.img-thumb {
	width: 150px;
	height: 100px;
	margin: 5px 10px;
}
.img-thumb-small {
	max-width: 100px;
	max-height: 75px;
	margin: 5px 10px;
}

.img-show-small {
	width: 100%;
	height: auto;
	max-width: 300px;
	max-height: 200px;
	margin: auto;
}

.img-show {
	// width: auto;
	// width: auto;
	// max-width: 300px;
	width: 300px;
	max-height: 200px;
	margin: auto;
	height: auto;
	object-fit: contain;
	object-position: center;
}

.img-show-logo {
	// width: auto;
	width: auto;
	max-width: 300px;
	width: 100%;
	max-height: 200px;
	margin: auto;
	height: auto;
	object-fit: cover;
	object-position: center;
}

.img-sample {
	text-align: center;
}

.about-us p {
	font-size: 18px;
}

.modal.and.carousel {
	position: fixed;
}

.overlay-layer {
	padding: 40px;
	background-color: rgba(0, 0, 0, 0.4);
}

.overlay-highlights {
	padding: 40px;
	background-color: rgba(0, 0, 0, 0.65);
}

.light-blue {
	background: #aab3d863;
}
.md-dark-blue {
	background: #a7aeca63;
}

.engine-bg {
	margin: 0 !important;
	background: url('/images/engine-bg-lighter.jpg') top center;
	background-size: cover;
	color: #ffffff;
}

.main-content {
	padding-top: 60px;
	text-align: left;
}

.fill {
	height: 100%;
	min-height: 100%;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

footer {
	background: #ffffff;
	padding: 10px;
	text-align: center;
}

/* removed from the main content container */

.flex-center {
	align-items: center;
	display: flex;
	justify-content: center;
}

.position-ref {
	position: relative;
}

.top-right {
	position: absolute;
	right: 10px;
	top: 18px;
}

.title {
	font-size: 84px;
}

.links > a {
	color: blue;
	padding: 0 25px;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.1rem;
	text-decoration: none;
	text-transform: none;
}

.m-b-md {
	margin-bottom: 30px;
}

/* design and animate the flash alerts  */

div.alert {
	position: absolute;
	top: 100px;
	left: 40%;
	z-index: 10000;
	animation: alert 4s forwards;
}

@keyframes alert {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		display: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 100;
}

h1 {
	font-size: 32px;
}

h2 {
	font-size: 28px;
}

h3 {
	font-size: 22px;
}

h4 {
	font-size: 18px;
}

h5 {
	font-size: 16px;
}

h6 {
	font-size: 14px;
}

.h-1 {
	font-size: 2.5rem;
}

.h-2 {
	font-size: 2rem;
}

.h-3 {
	font-size: 1.5rem;
}

.h-4 {
	font-size: 1.25rem;
}

.h-5 {
	font-size: 1rem;
}

.h-6 {
	font-size: 0.75rem;
}

.h-gray {
	color: #bbb;
}

.pagination {
	font-size: 16px;
}

.pagination li {
	padding: 0 !important;
}

.pagination li a {
	padding: 20px !important;
	margin: 0;
}

.pagination li a.arrow {
	padding: 10px 20px !important;
	font-size: 32px;
	margin: 0;
}

.pagination li a:hover {
	background: #eee;
}

/*
        Pages design
        */
.sectionActive a:hover {
	color: #000;
}
.sectionActive {
	border: 0 solid #000;
	color: #000;
	background: #fdf1d0;
	z-index: 99999999;
}

.bigrow {
	padding: 100px 0;
}

.white-background {
	background: #ffffff;
}

.btn-gradient {
	transition: background 0.3s ease-in-out;

	&:hover {
		color: white;
		background-color: $primary-color;
	}
}

.table-header,
.table-header th {
	background: rgba(205, 228, 252, 0.733);
	// background: #44526A;
	// color: #ffffff;
	text-transform: uppercase;
}
.table-header-dark-blue-gray,
.table-header-dark-blue-gray th {
	// background: rgba(205, 228, 252, 0.733);
	background: $dark-blue-gray;
	color: #ffffff;
	text-transform: uppercase;
}

.table-highlight {
	background: #f7f7f9;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
	background: #333;
}

.converter-container-0 .nav-item > .inactive {
	color: #333 !important;
}
.converter-container-0 .nav-item > .active {
	background: #007bff;
	color: #ffffff !important;
}

.converter-table-header {
	margin-top: 0 !important;
}
.alert-dark {
	background: #dddddd;
	color: #444;
}

/*
        item refers to anything: aircraft, buidlers, engines, etc
        
        the small thumbnail in an item profile/show page
        */

.image-list-thumbnail {
	height: auto;
	width: auto;
	max-width: 100%;
	max-height: 50px;
	// width: 100px;
}

.item-image-xsmall {
	width: auto;
	height: 25px;
}

.item-image-responsive {
	width: 100%;
	height: auto;
}

.item-image-small {
	width: auto;
	height: 50px;
}

.item-image-medium {
	width: 600px;
	height: auto;
}

.item-image-thumb {
	width: 75px;
	height: auto;
}

.table-aircraft th,
.table-engines th {
	width: 120px;
}

.table-aircraft th a:hover,
.table-engines th a:hover {
	text-decoration: none;
	width: 140px;
}

.table-search th:first-of-type {
	width: 50px;
}

.admin-profile-menu {
	display: none;
}

.aicraft-builder-column {
	margin-right: 10px;
}

.engine-builders-column {
	margin-left: 10px;
}

h3.compare-fields-column-title {
	font-size: 25px;
}

/* aircraft manager classes  */

.group {
	text-transform: capitalize;
}

.aircraft,
.cabin,
.doors,
.flight,
.fuselage,
.operators,
.units,
.weights,
.wings {
	background: #ffffff;
}

.build,
.development,
.engines,
.fuel,
.milestones,
.payload,
.value,
.engine,
.wheels {
	background: #e9f8ff;
}

.tooltip-circle,
a.tooltip-circle {
	border-radius: 80px;
	border: 1px solid #ccc;
	color: #ccc;
	padding: 1px 6px;
	font-size: 14px;
	text-align: right;
}

.modal-source-width {
	width: 100% !important;
}

.handle {
	cursor: move;
}
.clickable {
	cursor: pointer;
}
.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 60px;
	width: 100%;
	z-index: 100;
}

.engines-row {
	min-height: 75px;
}

a[aria-expanded='true'] {
	color: $dark-blue; // #777;
}

.pb-6,
.py-6 {
	padding-bottom: 4rem !important;
}

@media (max-width: 978px) {
	.container {
		// padding: 0 5px;
		// margin: 0;
		max-width: 960px;
	}

	body {
		padding: 0;
		font-size: 14px;
	}
	body {
		padding: 100px 25px 200px 25px;
	}

	.nav-admin {
		padding-top: 10;
	}

	.desktop-profile-menu {
		display: none;
	}

	.admin-profile-menu {
		display: inline;
	}
}

@media (max-width: 1280px) {
	.container {
		// padding: 0 5px;
		margin: auto;
		width: 100%;
	}
}

@media (max-width: 767px) {
	.container {
		padding: 0 5px;
		margin: 0;
		width: 100%;
	}

	.aicraft-builder-column {
		margin-right: 0;
	}

	.engine-builders-column {
		margin-left: 0;
	}
}

@media (max-width: 700px) {
	body {
		padding: 130px 0 0 0;
	}

	.main-content {
		padding-top: 70px;
	}

	.pagination {
		padding: 10px;
	}

	.jumbotron {
		padding: 8rem 1rem;
	}

	.desktop-profile-menu {
		display: none;
	}

	.item-image-xsmall {
		width: auto;
		height: 50px;
	}

	.wrapper {
		padding: $body-sm-mr $body-sm-ml;
	}
}

@media (max-width: 500px) {
	html,
	body {
		margin: 0;
	}

	.nav-admin {
		margin-top: 55px;
		margin-bottom: 30px;
		padding-top: 10px;
	}

	.pagination {
		padding: 10px;
	}

	.jumbotron {
		padding: 8rem 1rem;
	}

	.desktop-profile-menu {
		display: none;
	}

	.p-3 {
		padding: 0.8rem !important;
	}

	.nav-content {
		font-size: 0.9rem;
	}
}

@media (max-width: 500px) {
	.app-container {
		padding: 0;
		padding-bottom: 150px;
	}
	.nav-subline {
		display: none;
	}
	.wrapper {
		padding: 0;
	}
}
