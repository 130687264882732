
.guide a {
   color: $dark-blue-welcome !important;
 }
  .guide-blue {
   color: $dark-blue-welcome !important;
  }
  .guide-purple{
   color: #7031A0;
 }


 .guide .card-body ul li {
  line-height: 1.6;
 }