 
@-o-keyframes fadeIt {
0%   { background-color: #FFFFFF; }
50%  { background-color: #f1f5f7; }
100% { background-color: #FFFFFF; }
} 
@keyframes fadeIt {
0%   { background-color: #FFFFFF; }
50%  { background-color: #f1f5f7; }
100% { background-color: #FFFFFF; }
}

.highlight{    
  background-image:none !important; 
      -o-animation: fadeIt 2s ease-in-out; 
          animation: fadeIt 2s ease-in-out; 
}

.aircraft-index {
  background: $bg-light-blue;
}

/* remove styles and space from link list */
.aircraft-show ul, 
.aircraft-show ul li {
list-style: none; 
padding: 0;
margin: 0;
}
.aircraft-brief ul li {
  list-style-type: disc;
  margin-left: 15px;
}
      
/* keep the columns width even. */
.c40 {
width: 40%; 
}
.c50 {
width: 50%; 
}
.c60 {
width: 60%; 
}
/* Remove table lines. */
.table>tbody>tr>td,
.table>tbody>tr>th {
border-top: none;
}
.border-bottom-1 {
border-bottom: 1px solid #eeeeee;
}
.border-bottom-2 {
border-bottom: 2px solid #eeeeee;
}
.border-bottom-5 {
border-bottom: 5px solid #efefef;
}

.border-bottom-1-dark {
  border-bottom: 1px solid #757575;
}


a.back-to-top {
display: none;
width: 50px;
height: 50px;
color: #efefef;
z-index: 99999999;
  border: 2px solid #ccc;
text-align: center;
align: middle;
font-size: 22px;
padding: 6px 10px; 
-webkit-border-radius: 50px;
-moz-border-radius: 50px;
border-radius: 40px;
text-decoration: none;
}
@media ( max-width: 1400px) {
}
@media ( max-width: 600px) {
a.back-to-top {
    position: fixed;
  right: 50px;
  bottom: 110px;
}
}
 