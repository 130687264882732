  
.footer-bar {
  z-index: 999;
  padding: .01rem
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 60px; /* Set the fixed height of the footer here */
  background-color: #f5f5f5;
}

.luso-nav-link
{
  color: rgba(255, 255, 255, 1);
  display: block;
  // padding: 0.2rem 0.5rem;
  text-decoration: none;
}

.luso-nav-link:hover {
  color: $violet; // rgba(255, 255, 255, .75);
}

.border-top-1 {
  border-top: 1px solid white;
}

footer .luso-nav-link
{
  color: rgba(255, 255, 255, 1);
  display: block;
  padding: .2rem .5rem;
  text-decoration: none;
}

footer .luso-nav-link:hover {
  color: rgba(255, 255, 255, .75);
}

.footer-nav-second-row {
  z-index: 99999;
  border-bottom: 2px solid #F6F8FA; 
}