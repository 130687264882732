.modal-container {
	width: 545px;
	max-height: 85%;
	margin: 25px auto 0;
	padding: 10px 10px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
	overflow-y: initial !important;
	color: #222;
}

.wide-modal {
	width: 80%;
}

.text-right {
	text-align: right;
}

.form-label {
	display: block;
	margin-bottom: 0.5em;
}

.form-label > .form-control {
	margin-top: 0.5em;
}

.div-table-row-header {
	background: rgba(205, 228, 252, 0.733);
}
.div-table-row {
	line-height: 50px;
}
.div-table-row:hover {
	background: #efefef;
}
.modal-mask {
	position: fixed;
	z-index: 999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: opacity 0.3s ease;
}

.modal-form {
	display: block !important;
}

.modal-header {
	display: block;
	padding: 0.25rem 0.25rem;
	padding: 0;
}

.modal-header h3 {
	margin-top: 0;
	padding: 0.25rem 1rem 0.25rem 1rem;
}

.modal-body {
	margin: 20px 0;
	// padding: 0 10px;
	max-height: 65vh;
	height: 66%;
	overflow-y: auto;
	background: #fff;
}

.modal-body-small {
	height: 50vh;
}

.modal-body-large {
	margin: 20px 0;
	height: 100%;
	max-height: 55vh;
	overflow-y: auto;
}

.modal-child {
	z-index: 99999 !important;
}

@media only screen and (max-width: 400px) {
	.modal-container {
		width: 100%;
	}
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.deleteLink {
	color: blue;
	cursor: pointer;
}
.deleteLink:hover {
	color: #aaa;
}

.clickable {
	cursor: pointer;
}

.scrollable {
	overflow-y: auto;
	//   height: 100%;
	//   max-height: 550px;
	//   height: calc( 100% - 200px );
	//   max-height: 500px;
}

.scrollable-md {
	overflow-y: auto;
	height: 100%;
	max-height: 300px;
}

.modal-container-2 {
	width: 50%;
	background: #ffffff;
	color: #222;
	margin: 60px auto 0;
	padding: 0 10px;
	overflow-x: hidden;
	position: relative; 
}

.scrollable-lg {
	overflow-y: auto;
	max-height: 400px;
}

.modal-container-large {
	width: 80%;
	margin: 30px auto 0;
	padding: 20px 10px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
	overflow-y: initial !important;
}

.modal-container-search {
	width: 100%;
	margin: 0px auto 0;
	padding: 0px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
	overflow-y: initial !important;
}

@media (min-width: 1000px) {
	.modal-container-2 {
		width: 70%;
		height: auto !important;
		padding-bottom: 10px;
	}
	.modal-body,
	.modal-body-wrap {
		max-height: 70vh !important;
		height: auto !important;
		width: 100% !important;
		padding-bottom: 20px;
	}
}
@media (max-width: 1000px) {
	.converter-container {
		display: block;
		//  padding: 10px;
		//  max-width: 100%;
	}

	.scrollable-lg {
		overflow-y: auto;
		height: 100%;
	}

	.modal-container-2 {
		width: 80%;
	}
}

@media (max-width: 600px) {
	.converter-container {
		display: block;
		padding: 10px;
		max-width: 100%;
	}

	.scrollable-lg {
		overflow-y: auto;
		height: 100%;
		max-height: 540px;
	}

	.modal-container-2 {
		width: 100%;
	}
}
