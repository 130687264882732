/*
Description:
    subscription.blade.php page tables
Status: 
    Not currentyl in use
*/

@import "variables";
// Pricing Tables
.price-card {
    border: 1px solid #ddd;
    border-radius: 0;
    -webkit-box-shadow: 0 3px 0px 0 rgba(0, 0, 0, .08);
    box-shadow: 0 3px 0px 0 rgba(0, 0, 0, .08);
    transition: all .3s ease-in-out;
    padding: 2.25rem 0;
    position: relative;
    will-change: transform;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 5px;
        background-color: $primary-color;
        transition: 0.5s;
    }
    &:hover {
        transform: scale(1.05);
        -webkit-box-shadow: 0 20px 35px 0 rgba(0, 0, 0, .08);
        box-shadow: 0 20px 35px 0 rgba(0, 0, 0, .08);
        &:after {
            width: 100%;
        }
    }
    & .card-header {
        background-color: white;
        padding-left: 2rem;
        border-bottom: 0px;
    }
    & .card-title {
        margin-bottom: 1rem;
    }
    & .card-block {
        padding-top: 0;
    }
    & .list-group-item {
        border: 0px;
        padding: .25rem;
        color: $main-font-color;
        font-weight: $main-font-weight;
    }
    .list-group {
        min-height: 140px;
    }
}

// Price
.display-3 {
    letter-spacing: -.5rem;
    & .currency {
        font-size: 1.25rem;
        position: relative;
        font-weight: $main-font-weight + 100;
        top: -45px;
        letter-spacing: 0px;
    }
    & .period {
        font-size: 1rem;
        color: lighten($main-font-color, 20%);
        letter-spacing: 0px;
    }
}