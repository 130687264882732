// Body
$body-bg: #f5f8fa;

$body-mt: 7rem;
$body-mb: 7rem;
$body-ml: 2rem;
$body-mr: 2rem;

$body-sm-ml: 1.1rem;
$body-sm-mr: 1.1rem;
// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;
// Brands
$brand-primary: #3097d1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;
// Typography
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
$font-family-sans-serif: 'Avenir', sans-serif,
	// $font-family-sans-serif: "Raleway",
	sans-serif;
$font-size-base: 0.875rem;
$line-height-base: 1.6;
$text-color: #636b6f;
// Navbar
$navbar-default-bg: #fff;
// Buttons
$btn-default-color: #fff;
// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 65%);
// Panels
$panel-default-heading-bg: #fff;
// MY VARIABLES Varibles
$main-font: 'Roboto', sans-serif;
$primary-color: #57e2b2;
$primary-color-hover: #bcf8e3;
$light-gray: #f8f8f8;
$main-font-color: #808080;
$main-font-weight: 300;
$w-10: 10%;

// table colors
$bg-light-blue: #e8eef5;
$light-blue: #b7c5e3;
$lighter-blue: #d1e5fc;
$medium-light-blue: #b6c6e4;
$medium-blue: #92a9d7;
// $medium-blue: #92AAD7;
$bright-blue: #4472c4;
// $dark-blue: #365590;
$dark-blue: #305396;
$dark-blue-gray: #44536a;
$dark-blue-welcome: #4472c4;
$darkest-blue: #1f3764;
$some-blue: #365590;
$gray: #acb9ca;
$violet: #8d2ea4;

$bg-aux: #b3c6e7;

// $gold: #e6bd38;
// $gold-light: #FFE78D;
// $gold-light-2:rgb(245, 230, 174);
// $gold-dull: #FEE598;
// $gold-dark: #b47b00;

// @font-face {
//     font-family: 'calibre-regular';
//     font-style: normal;
//     src: url('/fonts/calibre/CalibreRegular.otf');
//     // font-weight: 900;
//     // src: url('../fonts/calibre/CalibreRegular.woff');
//     // padding-top: 10px;
//     src: url('/fonts/calibre/CalibreRegular.woff') format('woff');
//     padding-bottom: 0px;
//     margin-bottom: 0px;
//     margin: 0;
//     padding: 0;

//   }

// @font-face {
//     font-family: 'calibre-bold';
//     font-style: 900;
//     src: url('/fonts/calibre/CalibreBold.otf');
//     src: url('/fonts/calibre/CalibreBlack.otf');
//     src: url('/fonts/calibre/CalibreBold.woff') format('woff');
//     src: url('/fonts/calibre/CalibreSemibold.woff') format('woff');
//     padding-bottom: 0px;
//     margin-bottom: 0px;
//     margin: 0;
//     padding: 0;

//   }

$welcome-dark-blue: #305396;
$welcome-light-blue: #d6dce4;
