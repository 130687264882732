.font-sans {
	font-family: sans-serif, arial, tahoma;
}

@for $i from 12 to 72 {
	.t-#{$i} {
		font-size: #{$i}px;
	}
}

// 93A9D7
// 9BC2E6
$blue-1: #deeaf7; // light E8EEF5
$blue-2: #9bc2e6;
$blue-3: #92a9d7;
$blue-4: #5b9bd5;
$blue-5: #2f74b5;
$blue-6: #0062cc;
$blue-7: #305396;
$blue-8: #273a5f;

$blues: $blue-1, $blue-2, $blue-3, $blue-4, $blue-5, $blue-6, $blue-7, $blue-8;

@for $i from 1 through length($blues) {
	.text-blue-#{$i} {
		color: nth($blues, $i);
	}

	.bg-blue-#{$i} {
		background: nth($blues, $i);
	}

	.border-blue-#{$i} {
		border-color: nth($blues, $i);
	}

	// @for $p from 1 through length($blues) {
	//   .border-blue-#{$i}-#{$p} {
	//     border: #{$p}px solid nth($blues, $i);
	//   }
	// }
	// @for $p from 1 through length($blues) {
	//   .border-#{$i}-#{$p} {
	//     border: #{$p}px solid nth($blues, $i);
	//   }
	// }
}

@for $p from 1 through 10 {
	.border-#{$p}px {
		border-width: #{$p}px;
		border-style: solid;
	}
}

@for $i from 1 through 20 {
	.m-#{$i*5} {
		margin: 5px * $i !important;
	}
	.mt-#{$i*5} {
		margin-top: 5px * $i !important;
	}
	.ml-#{$i*5} {
		margin-left: 5px * $i !important;
	}
	.mr-#{$i*5} {
		margin-right: 5px * $i !important;
	}
	.mb-#{$i*5} {
		margin-bottom: 5px * $i !important;
	}

	.p-#{$i*5} {
		padding: 5px * $i !important;
	}
	.pt-#{$i*5} {
		padding-top: 5px * $i !important;
	}
	.pl-#{$i*5} {
		padding-left: 5px * $i !important;
	}
	.pr-#{$i*5} {
		padding-right: 5px * $i !important;
	}
	.pb-#{$i*5} {
		padding-bottom: 5px * $i !important;
	}
}

@media (max-width: 768px) {
	@for $i from 1 through 20 {
		.m-sm-#{$i*5} {
			margin: 5px * $i !important;
		}
		.mt-sm-#{$i*5} {
			margin-top: 5px * $i !important;
		}
		.ml-sm-#{$i*5} {
			margin-left: 5px * $i !important;
		}
		.mr-sm-#{$i*5} {
			margin-right: 5px * $i !important;
		}
		.mb-sm-#{$i*5} {
			margin-bottom: 5px * $i !important;
		}

		.p-sm-#{$i*5} {
			padding: 5px * $i !important;
		}
		.pt-sm-#{$i*5} {
			padding-top: 5px * $i !important;
		}
		.pl-sm-#{$i*5} {
			padding-left: 5px * $i !important;
		}
		.pr-sm-#{$i*5} {
			padding-right: 5px * $i !important;
		}
		.pb-sm-#{$i*5} {
			padding-bottom: 5px * $i !important;
		}
	}
}

@media (min-width: 1000px) {
	@for $i from 1 through 20 {
		.m-lg-#{$i*5} {
			margin: 10px * $i !important;
		}
		.mt-lg-#{$i*5} {
			margin-top: 1 10px * $i !important;
		}
		.ml-lg-#{$i*5} {
			margin-left: 10px * $i !important;
		}
		.mr-lg-#{$i*5} {
			margin-right: 10px * $i !important;
		}
		.mb-lg-#{$i*5} {
			margin-bottom: 10px * $i !important;
		}

		.p-lg-#{$i*5} {
			padding: 10px * $i !important;
		}
		.pt-lg-#{$i*5} {
			padding-top: 10px * $i !important;
		}
		.pl-lg-#{$i*5} {
			padding-left: 10px * $i !important;
		}
		.pr-lg-#{$i*5} {
			padding-right: 10px * $i !important;
		}
		.pb-lg-#{$i*5} {
			padding-bottom: 10px * $i !important;
		}
	}
}

$gold-1: #fafad2; // light
$gold-2: #eee8aa; // light 2
$gold-3: #fee598; // gold dull
$gold-4: #ffd866; // gold
$gold-5: #d4af37; // gold dark
$gold-6: #b47b00; // gold dark
$gold-7: #806000; // gold dark

$golds: $gold-1, $gold-2, $gold-3, $gold-4, $gold-5, $gold-6, $gold-7;

@for $i from 1 through length($golds) {
	.text-gold-#{$i} {
		color: nth($golds, $i);
	}

	.bg-gold-#{$i} {
		background: nth($golds, $i);
	}

	@for $p from 1 through length($golds) {
		.border-gold-#{$i}-#{$p} {
			border: #{$p}px solid nth($golds, $i);
		}
	}
}

$gray-0: #f2f2f2;
$gray-1: #efefef;
$gray-2: #eeeeee;
$gray-3: #d6dce4;
$gray-4: #bbbbbb;
$gray-5: #888888;
$gray-6: #555555;
$gray-7: #333333;
$gray-8: #000001;
$gray-9: #abb9ca;
$gray-10: #8493a4;

$grays: $gray-1, $gray-2, $gray-3, $gray-4, $gray-5, $gray-6, $gray-7, $gray-8, $gray-9, $gray-10;

@for $i from 1 through length($grays) {
	.text-gray-#{$i} {
		color: nth($grays, $i);
	}

	.bg-gray-#{$i} {
		background: nth($grays, $i);
	}

	@for $p from 1 through length($grays) {
		.border-gray-#{$i}-#{$p} {
			border: #{$p}px solid nth($grays, $i);
		}
	}
}

.rounded {
	border-radius: 2rem !important;
}

@for $i from 1 through 10 {
	.h-#{$i} {
		height: #{$i}px;
	}
}

.border-blue {
	border: 1px solid $blue-5;
}

@for $i from 1 through 10 {
	.border-top-#{$i} {
		border-top: #{$i}px solid #333;
	}
	.border-bottom-#{$i} {
		border-bottom: #{$i}px solid #333;
	}
	.border-right-#{$i} {
		border-right: #{$i}px solid #333;
	}
	.border-left-#{$i} {
		border-left: #{$i}px solid #333;
	}
}

// creats a list of classes for the height 5px .h-5px, .h-10px
@for $i from 1 through 50 {
	.h-#{$i*5}px {
		height: #{$i * 5}px;
	}
}

.border-top-1px-gray {
	border-top: 1px solid #ccc;
}

.border-bottom-1px-gray {
	border-bottom: 1px solid #ccc;
}
.border-bottom-1px-blue-6 {
	border-bottom: 1px solid $blue-6;
}

.v-align-middle {
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

.h1px {
	border-top: 1px solid $blue-5;
}

.h2px {
	border-top: 2px solid $blue-5;
}

.h3px {
	border-top: 3px solid $blue-5;
}

.h4px {
	border-top: 4px solid $blue-5;
}

.h5px {
	border-top: 5px solid $blue-5;
}

.anchor {
	padding-top: 50px;
	margin-top: -50px;
}

.no-text-decoration {
	text-decoration: none;
}

.filter-item {
	color: #007bff;
	cursor: pointer;
	margin: 2px;
	padding: 0.25rem;
	border-radius: 5px;
}
.filter-item:hover,
.active-filter {
	text-decoration: none;
	background-color: #007bff;
	color: white;
}

@media (max-width: 500px) {
	@for $p from 1 through 10 {
		.border-#{$p}px {
			border-width: #{$p}px;
			border-style: solid;
		}
	}

	@for $i from 1 through 20 {
		.m-sm-2 {
			margin: 2px !important;
		}
		.m-sm-#{$i*5} {
			margin: 5px * $i !important;
		}
		.mt-sm-#{$i*5} {
			margin-top: 5px * $i !important;
		}
		.ml-sm-#{$i*5} {
			margin-left: 5px * $i !important;
		}
		.m-sm-#{$i*5} {
			margin-right: 5px * $i !important;
		}
		.mb-sm-#{$i*5} {
			margin-bottom: 5px * $i !important;
		}

		.p-sm-#{$i*5} {
			padding: 5px * $i !important;
		}
		.pt-sm-#{$i*5} {
			padding-top: 5px * $i !important;
		}
		.pl-#{$i*5} {
			padding-left: 5px * $i !important;
		}
		.pr-#{$i*5} {
			padding-right: 5px * $i !important;
		}
		.pb-#{$i*5} {
			padding-bottom: 5px * $i !important;
		}
	}
}
