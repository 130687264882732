.cookies-popup {
	position: fixed;
	bottom: 10px;
	right: 0;
	background-color: #eee;
	color: #333;
	text-align: center;
	z-index: 1000000;
	border-radius: 0;
	max-width: 360px;
}
.cookies-close-x {
	position: absolute;
	top: 0;
	right: 0;
	background: #555;
	color: #fff;
	border: none;
	font-size: 20px;
	cursor: pointer;
}
.cookies-button-decline {
	background-color: #aaa;
	color: #444;
	border: none;
	padding: 5px 15px;
	border-radius: 0;
	cursor: pointer;
	width: 45%;
	font-weight: 600;
}
.cookies-button-accept {
	margin-right: 0;
	background-color: #1f5cb3;
	color: white;
	border: none;
	padding: 5px 15px;
	border-radius: 0;
	cursor: pointer;
	width: 50%;
	font-weight: 600;
}
