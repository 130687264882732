
.blue-banner {
    
    color: $dark-blue;
    background: #0F72BB
}
.welcome-column-odd
{
    color: $dark-blue;
    background: #9BC2E6
}
.welcome-column-even
{
    color: $gray-3;
    background: #5B9BD5
}
 
.anchor {
    display: block;
    position: relative;
    top: -140px;
    visibility: hidden;
}

.border-bottom-1-blue {
    border-bottom: 1px solid #0D73BA;
}


.welcome-bg-primary {
    background: #4472C4;
}


.welcome-bg-dark-blue {
    background: #305396;
}

.first_subline {
    font-size: 12px;
}

.border-t-2 {
    border-top: 2px solid #000;
}
.border-b-2 {
    border-bottom: 2px solid #000;
}
.border-r-4-white {
    border-right: 4px solid #fff;
}
 
.border-r-2-white {
    border-right: 2px solid #fff;
}
 
.dull-gold-box-light {
    background: #FFE78D;
}
.dull-gold-box {
    background: #FEE598; // original
}
 

.bg-welcome-counts  {
    background: #F2F2F2;
}

.bg-welcome-auxiliary  {
    background: #2F74B5;
}

.welcome-scroll .bg-website-menu-title {
    background: #B1C5EA;
}

.welcome-scroll .bg-light-blue {
    background: #D6DCE4;
}


.welcome-scroll .bg-pale-blue {
    background: #DAE1F2;
}


.welcome-scroll .bg-gray-light {
    background: #EDEDED;
}


.welcome-scroll .bg-lightest-gray {
    background: #F2F2F2;
}


.welcome-scroll .bg-light-gray {
    background: #f2f2f2;
}

.welcome-scroll .menu-label,
.welcome-scroll .menu-description {
    line-height: 1.1;
    color: #305396;
}


// welcome v3 layout 

.img-welcome {
    width: 320px;
}

.example-border {
   border: 3px solid #1C3667;
}


.vcenter-item{
    display: flex;
    align-items: center;
    text-align:center;
}

.welcome-scroll .text-dark-blue {
    color: #203764
}
 

.btn-success-dark {
    background: #1F7B17
} 

.welcome-scroll .scroll-footer-border {
    border-top: 10px solid #3B6FCA;
    border-bottom: 10px solid #3B6FCA;
}

.welcome-scroll .scroll-footer {
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
}

.welcome-scroll .text-footer {
    color: #203764;
}
 
 

@media ( max-width: 660px) {

    .img-welcome {
        width: 30%;
    }

}
 
    
