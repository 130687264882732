

ul.launch-menu {
  display: flex;
  align-items: stretch; /* Default */
  justify-content: space-between;
  width: 100%;
  background: #cacaca;
  margin: 0;
  padding: 0;

  list-style: none;
  background-color: #A0B8E3;
  color: #fff;
}


ul.launch-menu .launch-nav-item {

  display: block;
  flex: 0 1 auto; /* Default */
  list-style-type: none; 
  padding: 10px 20px;
}

ul.launch-menu .launch-nav-item a {
  color: #fff;
}