@import 'variables';

.nav-main,
.nav-utilities-popup {
	background: #215cb6;
}

.navbar {
	padding: 0.25rem 1rem;
	padding: 0.15rem 1rem;
}

.navbar-brand {
	font-size: 24px;
	margin: 0;
	padding: 0;
}

.navbar-white-text {
	color: #fff;
}

.nav-dropdown-entry {
	border: 0 solid #fff;
	background-color: #8796ae;
	padding: 10px 20px;
}

.nav-box-item {
	background-color: $medium-blue;
	color: #000;
}

.nav-box-item a {
	color: #fff;
}

.dropdown-menu .dropdown-item a {
	color: #333;
}

a.user-menu-dropdown {
	color: $dark-blue;
}

.dropdown-heading {
	background-color: $dark-blue;
	color: #fff;
}

.dropdown-heading:hover {
	background-color: $dark-blue;
	color: #fff;
}

.dropdown-entry-light-blue {
	background-color: $light-blue;
}

.dropdown-entry-medium-light-blue {
	background-color: $medium-light-blue;
}
.dropdown-entry-medium-blue {
	background-color: $medium-blue;
}

.dropdown-capacities {
	position: relative !important;
	border: 0 !important;
	border-top: 1px #aaa !important;
	border-bottom: 1px #aaa !important;
	text-align: center;
}

.referer-1 {
	border: 1px solid #000;
}
.referer-2 {
	border-top: 1px solid #000;
	border-left: 0 solid #000;
	border-right: 1px solid #000;
	border-bottom: 1px solid #000;
}

.btn-burger {
	color: #fff;
}

.dropdown-menu {
	left: auto;
	z-index: 99999999 !important;
}
.tooltip {
	position: fixed;
	z-index: 100 !important;
}

.reference-title-bar {
	border-top: 1px solid #aaa;
	margin: auto;
	bottom: 45px;
	padding: 5px;
}

.breadcrumb-container {
	z-index: 99 !important;
	margin-top: 3rem;
	margin-left: 0;
	margin-right: 0;
	background: $body-bg;
	// padding: 5px;
	padding-top: 18px;
	padding-left: 100px;
	height: 3rem;
	border: 0 solid;
	// background: #f2f2f2;
}

.menu-square-icon-entry {
	// padding: 15px 15px 10px 15px !important;
	// padding: 5px 5px 5px 5px !important;
	line-height: 0.75rem;
	min-width: 55px;
}

@media (max-width: 999px) {
	.reference-title-bar {
		display: none;
	}
	.breadcrumb-container {
		margin-top: 3.75rem;
		margin-left: 0;
		padding-top: 0;
		height: 1.875rem;
		padding-left: 75px;
	}
}

@media (max-width: 574px) {
	.reference-title-bar {
		display: none;
	}
	.breadcrumb-container {
		padding-left: 50px;
		margin-left: 0;
		padding-top: 0;
	}
}

.my-dropdown-toggle::after {
	content: none;
}

// .navbar-nav a:active{
//     font-family: 'calibre-bold';
//     font-weight: 900;
// }
.nav-item {
	line-height: 1.3;
}

// .navbar-nav .nav-link.active {
//     font-family: 'calibre-bold';
//     font-weight: 900;
//     // background-color: red;
// }

ul.aux .dropdown-menu {
	padding: 0;
	margin-left: 0;
	padding-left: 0;
}

.border-bottom-black {
	border-bottom: 1px solid #000;
}

.nav-portals {
	padding: 0.4rem;
	// background: #4472C4;
	text-decoration: none;
}
.nav-user {
	padding: 0.5rem;
}
.nav-ref {
	padding: 0.5rem;
	width: 250px;
	text-align: center;
	color: $dark-blue;
}

.menu-admin {
	top: 60px;
	left: 0;
	position: fixed;
	z-index: 99999;
}

@media (max-width: 1250px) {
	.nav-ref {
		width: 200px;
	}
}

@media (max-width: 1150px) {
	.nav-ref {
		width: 160px;
	}
}
@media (max-width: 1050px) {
}

@media (max-width: 500px) {
	.bulletin-nav-container a {
		color: #fff !important;
		text-align: left;
	}
}

@media screen and (orientation: landscape) and (max-height: 550px) {
	.dropdown-menu-right {
		max-height: 350px;
		overflow-x: auto;
		z-index: 999999;
	}
}

@media (max-width: 550px) {
	.nav-logo {
		display: block;
		width: 100% !important;
		text-align: center;
	}
}

@media (max-width: 550px) {
	.w-sm-100 {
		width: 100% !important;
	}
	.w-sm-50 {
		width: 50% !important;
	}
	.searchglobal-container {
		width: 100% !important;
	}
}
