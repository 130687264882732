
/*
Description:
    pricing.blade.php
Status: 
    active
*/
            
// @import "variables"; // Pricing Tables




.table-striped-blue>tbody>tr:nth-child(odd)>td, 
.table-striped-blue>tbody>tr:nth-child(odd)>th {
    background-color:  $light-blue;
}
.table-striped-blue>tbody>tr:nth-child(even)>td, 
.table-striped-blue>tbody>tr:nth-child(even)>th {
    background-color: $medium-blue;

}

.table-striped-white>tbody>tr:nth-child(odd)>td, 
.table-striped>tbody>tr:nth-child(odd)>th {
    background-color:  $bg-light-blue;
}

.table-striped-blue td a {
    color: #000;
}