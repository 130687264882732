 
@font-face {
    font-family: 'Avenir';
    src: url('/fonts/avenir/AvenirLTStd-Roman.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/avenir/AvenirLTStd-Book.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/fonts/avenir/AvenirLTStd-Black.woff2') format('woff2'),
        url('/fonts/avenir/AvenirLTStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'Avenir';
//     src: url('https://www.airlibrary.aero/public/font/avenir/AvenirLTStd-Black.woff2') format('woff2'),
//         url('/font/avenir/AvenirLTStd-Black.woff') format('woff');
//     font-weight: 900;
//     font-style: normal;
//     font-display: swap;
// }


 
 

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-RegularItalic.woff2') format('woff2'),
//       url('/font/calibre/Calibre-RegularItalic.woff') format('woff');
//   font-weight: normal;
//   font-style: italic;
//   // font-display: swap;
//   line-height: 100%;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-MediumItalic.woff2') format('woff2'),
//       url('/font/calibre/Calibre-MediumItalic.woff') format('woff');
//   font-weight: 500;
//   font-style: italic;
//   // font-display: swap;
//   // font-variant-position: normal;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-Medium.woff2') format('woff2'),
//       url('/font/calibre/Calibre-Medium.woff') format('woff');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-Semibold.woff2') format('woff2'),
//       url('/font/calibre/Calibre-Semibold.woff') format('woff');
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-Thin.woff2') format('woff2'),
//       url('/font/calibre/Calibre-Thin.woff') format('woff');
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-Light.woff2') format('woff2'),
//       url('/font/calibre/Calibre-Light.woff') format('woff');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-ThinItalic.woff2') format('woff2'),
//       url('/font/calibre/Calibre-ThinItalic.woff') format('woff');
//   font-weight: 100;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-BlackItalic.woff2') format('woff2'),
//       url('/font/calibre/Calibre-BlackItalic.woff') format('woff');
//   font-weight: 900;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-SemiboldItalic.woff2') format('woff2'),
//       url('/font/calibre/Calibre-SemiboldItalic.woff') format('woff');
//   font-weight: 600;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-Bold.woff2') format('woff2'),
//       url('/font/calibre/Calibre-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-BoldItalic.woff2') format('woff2'),
//       url('/font/calibre/Calibre-BoldItalic.woff') format('woff');
//   font-weight: bold;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-Black.woff2') format('woff2'),
//       url('/font/calibre/Calibre-Black.woff') format('woff');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-LightItalic.woff2') format('woff2'),
//       url('/font/calibre/Calibre-LightItalic.woff') format('woff');
//   font-weight: 300;
//   font-style: italic;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Calibre';
//   src: url('/font/calibre/Calibre-Regular.woff2') format('woff2'),
//       url('/font/calibre/Calibre-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

