.home-btn.btn {
	text-transform: uppercase;
	font-size: 0.75rem;
	font-weight: 700;
	color: #a6a6a6;
	border-radius: 0;
	padding: 0.75rem 1.25rem;
	letter-spacing: 1px;
	min-width: 150px;
}

.lead a {
	font-size: 18px;
}

.home-btn.btn-gradient {
	color: #fff;
	background-color: #57e2b2;
	transition: color 0.3s ease-in-out;
	transition: background 0.3s ease-in-out;
}
.home-btn.btn-gradient:hover {
	color: #333;
	background-color: #bcf8e3;
	transition: color 0.3s ease-in-out;
}

.btn-circle.btn-sm,
.btn-group-sm > .btn-circle.btn {
	width: 22px;
	height: 22px;
	padding: 0;
	border-radius: 15px;
	font-size: 15px;
	text-align: center;
	font-weight: 800;
	// color: #565656;
}

.btn-circle.btn-md {
	width: 50px;
	height: 50px;
	padding: 7px 10px;
	border-radius: 25px;
	font-size: 10px;
	text-align: center;
}

.btn-help {
	margin: 0 10px;
	margin-bottom: 4px;
	background: #efefef;
	background: #4285f4;
	background: #9ec0f7;
	border: #efefef;
	font-weight: 700;
	color: white;
	text-shadow: 1px 1px 1px #444;
}

.btn-help:hover {
	background: #4285f4;
	color: #fff;
	box-shadow: 1px 1px 1px #444;
}

.border-2 {
	border: 2px solid #444;
}
.border-3 {
	border: 3px solid #444;
}
.border-4 {
	border: 4px solid #444;
}
.border-radius-0 {
	border-radius: 0;
}

.pb-8 {
	padding-bottom: 4.8rem !important;
}

// helps display the right sort indicator

.sort {
	border: solid #333;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 2px;
	margin-left: 5px;
}

.right {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
}

.left {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
}

.sort.up,
.sort.asc {
	transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	margin-top: 2px;
}

.sort.down,
.sort.desc {
	transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	margin-bottom: 2px;
}

.not-rounded {
	border-radius: 0;
}

.my-mb-1 {
	margin-bottom: 0.25rem !important;
}

.my-m-2 {
	margin: 0 0.5rem !important;
}

.my-p-1 {
	padding: 0.25rem !important;
	// padding-top: 0.25 !important;
	// padding-bottom: 0 !important;
}

.list-none ul li {
	list-style: none;
}
// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-up($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

//       @each $size in $sizes {
//         .my-mb-#{$infix},
//         .myy-mb-#{$infix}
//          {
//           margin-bottom: #{$size} !important
//         }
//       }

//   }
// }

.hidden {
	display: none;
}

.error {
	border: 2px solid red;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
