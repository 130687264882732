.searchglobal-container {
	// left: auto;
	// right: auto;
	width: 100%;
	// position: absolute;
	margin: auto;
}

.searchglobal-container * {
	padding: 0;
	margin: 0;
}

.searchglobal-container input::placeholder {
	color: #999 !important;
}

.searchglobal-container input {
	color: #000;
	z-index: 999999999;

	// top: 0;
	padding: 0;
	margin-top: 0;

	padding: 0 15px 7px 10px;
	padding: 0;
}

.searchglobal-container button {
	background: transparent;
	border: none;
	cursor: pointer;
	display: inline-block;
	font-size: 20px;
	padding: 0;
	z-index: 2;
}

.searchglobal-container input:focus + button .fa-search {
	color: red;
}

.auto-complete {
	position: absolute;
	z-index: 999999;
	box-shadow: 1px 2px 2px rgba(20, 20, 20, 0.3);
	border-radius: 3px 3px 3px 3px;
	padding-left: 0px;
	border: 5px solid #fbeaa3;
}

.widen {
	display: block;
	width: 80%;
	padding: 4px;
}

.widen2 {
	display: block;
	width: 100%;
	padding: 2px;
}

.no-hover:hover {
	background: #fff;
}

.search-body {
	overflow-y: scroll;
	max-height: 80vh;
	position: relative;
	flex: 1 1 auto;
	border: 5px solid #fbeaa3;
}

.search-body-global {
	position: absolute;
	margin: auto;
	width: 90%;
	top: 50px;
	left: 0;
	right: 0;
	box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.51);
	-webkit-box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.51);
	-moz-box-shadow: -1px 2px 5px 0px rgba(0, 0, 0, 0.51);
}

.tbody {
	background-clip: padding-box;
	background-color: #fff;
	border: 1px solid #ced4da;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.overflow {
	overflow: scroll;
	height: 100vh;
	max-height: 400px;
}

.search-box-button {
	color: #999;
	padding: 0;
	padding-left: 5px;
	margin: 0;
	width: 30px;
	position: absolute;
	// cursor: none !important;
}

.search-box-input {
	padding: 10px;
	width: 100%;
	font-size: 16px;
	border: 0;
	border-radius: 0;
}
.stickysearch {
	width: 100%;
	display: inline;
}

@media (max-width: 990px) {
	.searchglobal-container {
		width: 65%;
	}
	.search-box-input {
		width: 80%;
	}
}

@media (max-width: 990px) {
	// .searchglobal-container {
	//   width: 85%;
	// }
	.search-box-input {
		width: 100%;
	}
}
